import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import PortableText from 'react-portable-text';

import styled from 'styled-components';
import SEO from '../components/layout/SEO';
import Breadcrumb from '../components/layout/Breadcrumb';
import PostHeader from '../components/blog/PostHeading';
import SidebarContact from '../components/widgets/sidebarContact';

const PageStyles = styled.div`
  .content {
    padding: 60px 0;
    display: grid;
    grid-template-columns: 3.5fr 1fr;
    grid-gap: 50px;
  }

  @media only screen and (max-width: 780px) {
    .content {
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
    }
  }

  .post-heading {
    font-size: 16px;
    font-weight: 600;
  }
`;

export default function PostPage({ data, location }) {
  const { post } = data;
  const featuredImage = getImage(post.featuredImage.asset);

  return (
    <PageStyles>
      <SEO
        title={post.heading}
        location={location.href}
        image={post.featuredImage.asset.url}
      />

      <Breadcrumb>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/news">News</Link>
        </li>
        <li>
          <Link to={`/news/${post.slug.current}`}>{post.heading}</Link>
        </li>
      </Breadcrumb>

      <div className="container content grid">
        <div className="main-content">
          <GatsbyImage image={featuredImage} alt={post.heading} />

          <PostHeader title={post.heading} date={post._createdAt} />

          <PortableText content={post._rawBody} />
        </div>

        <div className="sidebar">
          <SidebarContact />
        </div>
      </div>
    </PageStyles>
  );
}

export const query = graphql`
  query ($id: String!) {
    post: sanityArticle(id: { eq: $id }) {
      _createdAt
      _rawBody
      id
      heading
      featuredImage {
        asset {
          url
          gatsbyImageData(layout: FULL_WIDTH, width: 814, height: 385)
        }
      }
      slug {
        current
      }
    }
  }
`;

PostPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
